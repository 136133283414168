.formBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input,
textarea {
    width: 80vw;
    max-width: 30rem;
    margin-bottom: 1rem;
    margin-top: 0.2rem;
}

.delete,
.btn {
    cursor: pointer;
    background: red;
    width: fit-content;
    padding: 1rem 1.5rem;
    color: white;
    border-radius: 0.5rem;
    margin-top: 0;
}

.btn {
    background: rgb(0, 102, 255);
}