html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Rubik', sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    max-width: 100vw;
    overflow: hidden;
}